@import '~normalize.css/normalize.css';
@import 'partials/_variables.scss';
@import 'partials/_accordion.scss';
@import 'partials/_slider.scss';
@import 'partials/_columns.scss';
@import 'partials/_buttons.scss';
@import 'partials/_forms.scss';
@import 'partials/_messages.scss';

@import "partials/_cart.scss";

// Font Proxima Nova
@import url("https://use.typekit.net/wim0shj.css");

$max-width: 1440px;
$min-width: 320px;

// Global Styles
*,
*:before,
*:after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body,
html {
    font-family: $font;
    position: relative;
    text-align: center;
    -webkit-overflow-scrolling: touch;

    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

// Error pages
.text-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 0.5em;
}

/* --------------------------------------------
   Temp Holding Page
-------------------------------------------- */

/* --------------------------------------------
   HTML5 Doctor Reset
-------------------------------------------- */

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
slideshow,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
}

article,
slideshow,
aside,
details,
figcaption,
figure,
footer,
footernave,
header,
hgroup_wrap,
hgroup,
hgroup2,
hgroup3,
menu,
middle,
nav,
section {
    display: block;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
}

mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: bold;
}

del {
    text-decoration: line-through;
}

abbr[title],
dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

input,
select {
    vertical-align: middle;
}

body {
    position: relative;
}

select,
input,
textarea,
button {
    font: 99% sans-serif;
}

pre,
code,
kbd,
samp {}

html {
    overflow-y: scroll;
}

a:hover,
a:active {
    outline: none;
}

ul,
ol {
    margin-left: 2em;
}

ol {
    list-style-type: decimal;
}

nav ul,
nav li {
    margin: 0;
    list-style: none;
    list-style-image: none;
}

small {
    font-size: 85%;
}

strong,
th {
    font-weight: bold;
}

td {
    vertical-align: top;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 15px;
}

textarea {
    overflow: auto;
}

.ie6 legend,
.ie7 legend {
    margin-left: -7px;
}

input[type="radio"] {
    vertical-align: text-bottom;
}

input[type="checkbox"] {
    vertical-align: bottom;
}

.ie7 input[type="checkbox"] {
    vertical-align: baseline;
}

.ie6 input {
    vertical-align: text-bottom;
}

label,
input[type="button"],
input[type="submit"],
input[type="image"],
button {
    cursor: pointer;
}

button,
input,
select,
textarea {
    margin: 0;
}

input:valid,
textarea:valid {}

input:invalid,
textarea:invalid {
    border-radius: 1px;
    -moz-box-shadow: 0px 0px 5px red;
    -webkit-box-shadow: 0px 0px 5px red;
    box-shadow: 0px 0px 5px red;
}

.no-boxshadow input:invalid,
.no-boxshadow textarea:invalid {
    background-color: #f0dddd;
}

::-moz-selection {
    background: #000;
    color: #fff;
    text-shadow: none;
}

::selection {
    background: #000;
    color: #fff;
    text-shadow: none;
}

a:hover {
    color: #ED2023;
}

button {
    width: auto;
    overflow: visible;
}

.ie7 img {
    -ms-interpolation-mode: bicubic;
}

main {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
}

body,
select,
input,
textarea {
    color: #333;
}

h1,
h2,
h3 {
    color: #2C2C2C;
    font-family: $font;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
}

h4,
h5,
h6 {
    color: #2C2C2C;
    font-family: $font;
    font-style: normal;
    font-weight: 600;
    text-transform: none;
}

/* Clearfix */

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

html {
    -webkit-text-size-adjust: 100%;
    /* Prevent font scaling in landscape while allowing user zoom */
}

body {
    font-size: 11pt;
    margin: 0 0 0 0;
    background-color: #000;
    font-family: $font;
    font-style: normal;
    font-weight: 300;
    color: #ffffff;
}

img {
    border: none;
    max-width: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

p {
    margin: 0 0 15px 0;
}

p:last-child {
    margin-bottom: 0;
}

h1 {
    font-size: 18pt;
    margin: 0 0 5px 0;
}

h2 {
    font-size: 14pt;
    margin: 0 0 3px 0;
}

h3 {
    font-size: 10pt;
    margin: 0 0 3px 0;
}

hr {
    margin: 0 0 0 0
}

ul {
    list-style: none;
    margin: 15px 0 15px 20px;
}

ul li {
    line-height: 25px;
    padding-left: 0px;
}

ul a {
    color: #ffffff;
    text-decoration: none;
}

ul a:hover {
    color: #ED2023;
    text-decoration: underline;
}

.container {
    width: 100%;
    padding: 0 40px;
    margin: 0 auto;
    overflow: auto;
    max-width: 1200px;
}

/* Global */

.row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header {
    position: relative;
    padding: 1em 0;
}

header .logo img {
    width: auto;
    height: 120px;
    object-fit: contain;
    object-position: center;
}

header nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

header nav ul {
    display: flex;
}

header nav ul li {
    font-size: 1.125rem;
    margin-right: 1.5em;
    text-transform: uppercase;
    font-weight: 500;
}

header nav ul li a:hover {
    color: #ED3D40;
    text-decoration: none;
}

ul.social {
    display: flex;
}

ul.social li {
    list-style-type: none;
    margin-right: 0;
}

ul.social li a {
    width: 32px;
    height: 32px;
    display: block;
    background-color: #ffffff;
    border-radius: 100%;
    padding: 5px;
    margin: 0 0.25em;
    text-decoration: none;
}

ul.social li a:hover {
    background-color: #ED3D40;
}

ul.social li img {
    width: 100%;
    height: auto;
}

footer.legal {
    background: #000000;
    padding: 1rem 0;
    letter-spacing: 0.3px;
    text-align: left;
    font-weight: 200;
    font-size: 85%;
    color: #ffffff;
}

footer.legal .row {
    flex-wrap: wrap;
}

footer.legal p {
    width: 100%;
}

footer.legal a {
    padding-bottom: 0.125rem;
    border-bottom: 1px solid transparent;
    color: #ffffff;
}

footer.legal a:hover {
    border-bottom-color: #ffffff;
    color: #ffffff;
}

footer.legal .divider {
    margin: 0 0.25rem;
}

footer.legal .terms+.divider {
    display: none;
}

footer.legal .colophon {
    display: block;
    margin-top: 0.25rem;
}

footer.legal .acknowledgement {
    background: #000000;
    padding: 0.75rem 0 0;
    color: #ffffff;
}

/* Homepage */

#home-hero {
    display: block;
    width: 100%;
    height: 700px;
}

#home-hero .home-hero-img {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: 1200px;
    object-fit: cover;
    object-position: center;
}

#home-hero .credit {
    display: block;
    padding-right: 6px;
    font-size: 95%;
    text-align: right;
}

#home-about {
    display: block;
    padding: 0;
    margin: 4em auto;
}

#home-about .row {
    align-items: flex-start;
    justify-content: space-between;
}

#home-about .row .column {
    width: 100%;
    text-align: left;
}

#home-about .row .column.left h1 {
    font-family: $font;
    font-size: 2rem;
    margin: 0 0 1.5rem 0;
    color: #ffff;
}

#home-about .row .column.left p {
    color: #FFF;
    font-family: $font;
    font-size: 1.24rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#home-about .row .column.right {
    display: flex;
    justify-content: flex-end;
}

#home-about .column.right .map-east-end {
    width: auto;
    height: 425px;
    object-fit: contain;
    object-position: center;
}

#home-logos {
    width: 100%;
    padding: 3em 0;
    background: #E5E5E5;
}

.home-logos-grid {
    display: grid;
    gap: 3em;
    grid-template-columns: repeat(6, 1fr);
    overflow-y: hidden;
}

.home-logos-grid a.logo-item {
    list-style-type: none;
    text-decoration: none;
    transition: 0.3s;
}

.home-logos-grid a.logo-item:hover {
    transform: scale(1.1);
}

.home-logos-grid a.logo-item img {
    display: block;
    width: 100%;
    height: 160px;
    object-position: center;
    object-fit: contain;
}

/* Responsive */

@media only screen and (max-width: 769px) {
    .container {
        padding: 0 20px;
    }

    .row {
        flex-direction: column;
    }

    header .logo img {
        height: 80px;
    }

    header .row {
        flex-direction: row;
    }

    ul.social li a {
        width: 28px;
        height: 28px;
    }

    #home-hero {
        height: 15rem;
    }

    #home-about {
        padding: 0 20px;
        margin: 2em auto;
    }

    #home-about .row {}

    #home-about .row .column.left h1 {
        font-size: 1.75rem;
    }

    #home-about .row .column.left p {
        font-size: 1rem;
    }

    #home-about .row .column.right {
        margin-top: 2em;
    }

    #home-about .column.right .map-east-end {
        width: 100%;
        height: auto;
    }

    #home-logos {
        padding: 2em 0;
    }

    .home-logos-grid {
        gap: 1em;
        grid-template-columns: repeat(3, 1fr);
    }

    .home-logos-grid a.logo-item img {
        height: 95px;
    }
}
